/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { postPincodeHandler } from '../service';

class Pincode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      searchBy: '',
      serviceable: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.inputChange = this.inputChange.bind(this);
  }

  handleChange(e) {
    if (e.charCode === 13) {
      this.refs.searchInput.value = this.refs.searchInput.value.trim();
      this.handleSearch();
    }
  }

  inputChange(e) {
    this.setState({ searchInput: e.target.value, serviceable: null });
  }

  handleCheck(e) {
    const { id } = e.target;
    this.setState({ searchBy: id });
  }

  handleSearch() {
    // console.log(this.refs.searchInput.value.trim());
    let queryId = this.refs.searchInput.value.trim();
    let data = [
      {
        drop_pincode: queryId
      }
    ];
    this.props.fetchPincodeHandler(data).then(res => {
      if (res.meta && res.meta.success) {
        //  console.log(res.result.serviceable);
        this.setState({ serviceable: res.result.serviceable });
      } else {
        // console.log(res);
      }
    });
  }
  componentDidMount() {
    // console.log('componentDidMount');
    const { user, page, tracking } = this.props;
    if (user.env !== 'development') {
      const { location } = this.props.history;

      ReactGA4.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        enterprise: user.enterprise,
        tracking_source: page.source || '',
        enterprise_id: tracking.theme.enterprise_user_id
      });

      ReactGA.set({ dimension1: user.enterprise });
      ReactGA.set({ dimension7: page.source || '' });
      ReactGA.set({ dimension9: tracking.theme.enterprise_user_id });
      ReactGA.pageview(location.pathname + location.search);
    }
  }
  render() {
    const { user } = this.props;

    return (
      <div
        className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 main-container
          ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
          ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
          ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
          ${
            user.subdomain == '1mg' || user.subdomain == '1mg-test'
              ? 'onemg'
              : user.subdomain
          }`}
      >
        <div className="row col-gutter-200">
          <div className="col-md-24 col-xs-24">
            <div className="search">
              <div className="inputGroup left inline-radio">
                <label className="label">Check Pincode Serviceability</label>
              </div>
              <div className="clear-both" />
              <p className="input-group mrg-top-10">
                <input
                  type="text"
                  ref="searchInput"
                  className="textbox border-left-radius"
                  placeholder="Enter your pincode"
                  onChange={e => this.inputChange(e)}
                  onKeyPress={e => this.handleChange(e)}
                />
                <span
                  className="icon-search"
                  onClick={this.handleSearch}
                  tabIndex="0"
                  role="button"
                />
              </p>
              {this.state.serviceable ? (
                <>
                  <div className="full-width mg-top-10">
                    <div className="pincode-servicable">
                      <p className="li-ht-24">
                        <span className="icon-order order-icon-pincode" />
                        <strong className="vr-align-super">
                          Yay! we are delivering to {this.state.searchInput}
                        </strong>
                      </p>
                    </div>
                  </div>
                </>
              ) : this.state.serviceable === null || undefined ? null : (
                <>
                  <div className="full-width mg-top-10">
                    <div className="pincode-not-servicable ">
                      <p className="li-ht-24">
                        <span className="icon-order order-icon-pincode" />
                        <strong className="vr-align-super">
                          Sorry! we are are not delivering to{' '}
                          <b> {this.state.searchInput}</b>
                        </strong>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Pincode.propTypes = {
  user: PropTypes.object,
  page: PropTypes.object,
  tracking: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  serviceable: PropTypes.boolean,
  fetchPincodeHandler: PropTypes.func
};

Pincode.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  page: state.page,
  tracking: state.tracking
});

const mapDispatchToProps = dispatch => ({
  fetchPincodeHandler: query => dispatch(postPincodeHandler(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pincode)
);
