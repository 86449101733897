/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { trackingResetHandler, trackingHandler } from '../service';
import { strings } from '../utils/localizedStrings';
import InvalidShipmentImage from './InvalidShipmentImage';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      searchBy: 'order_id',
      invalidSearch: false,
      message: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.inputChange = this.inputChange.bind(this);
  }

  handleChange(e) {
    if (e.charCode === 13) {
      this.refs.searchInput.value = this.refs.searchInput.value.trim();
      this.handleSearch();
    }
  }

  inputChange(e) {
    this.setState({ searchInput: e.target.value });
  }

  clearInput = () => {
    this.setState({ searchInput: '', invalidSearch: false, message: '' });
    this.refs.searchInput.value = '';
  };

  handleCheck(e) {
    const { id } = e.target;
    this.setState({ searchBy: id });
  }

  handleSearch(udfKeys) {
    // const { routePrefix } = this.props.user;
    let queryId = this.refs.searchInput.value.trim();
    const { searchBy } = this.state;
    const { search, hash, pathname } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);
    let query = {};
    if (searchBy === 'order_id') {
      queryId = query.orderId = encodeURIComponent(queryId);
    } else {
      query.waybill = queryId;
    }
    query.app_only = searchParams.get('app_only');
    this.props.fetchTrackingHandler(query).then(res => {
      if (res.meta && res.meta.success) {
        if (queryId !== '') {
          if ('shipments' in res.result && res.result.shipments.length > 0) {
            this.props.history.push(
              `${pathname}my-order?${searchBy}=${decodeURIComponent(queryId)}${
                query.app_only ? `&app_only=${query.app_only}` : ''
              }`
            );
          } else {
            this.props.history.push(
              `${pathname}?${searchBy}=${decodeURIComponent(queryId)}${
                query.app_only ? `&app_only=${query.app_only}` : ''
              }`
            );
          }
        }
      } else {
        const { counter } = this.state;
        this.setState({ counter: counter + 1 });
        if (res.meta && res.meta.message) {
          const { lang } = this.props.user;
          if (res.meta.status === 401) {
            this.setState({ invalidSearch: true, message: res.meta.message });
          } else {
            this.setState({
              invalidSearch: true,
              message:
                udfKeys?.incorrectSearchText ?? strings[lang].incorrectSearch
            });
          }
          if (this.state.counter < 2) {
            this.fetchTracking();
          }
        } else {
          this.setState({ invalidSearch: true, message: res.message });
          if (this.state.counter < 2) {
            this.fetchTracking();
          }
        }
      }
    });
  }
  componentDidMount() {
    const { user, page, tracking } = this.props;
    this.props.trackingResetHandler();
    if (user.env !== 'development') {
      const { location } = this.props.history;

      ReactGA4.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        enterprise: user.enterprise,
        tracking_source: page.source || '',
        enterprise_id: tracking.theme.enterprise_user_id
      });

      ReactGA.set({ dimension1: user.enterprise });
      ReactGA.set({ dimension7: page.source || '' });
      ReactGA.set({ dimension9: tracking.theme.enterprise_user_id });
      ReactGA.pageview(location.pathname + location.search);
    }
    if (tracking.theme?.meta_data?.is_search_by_order_id === false) {
      this.setState({ searchBy: 'waybill' });
    }
  }
  render() {
    const { user, tracking } = this.props;
    const { lang } = user;
    const { udfKeys } = tracking.theme;
    udfKeys.titleTextClassName = udfKeys.titleTextClassName ?? '';

    let searchPlaceholder = '';
    if (this.state.searchBy === 'waybill') {
      searchPlaceholder =
        udfKeys?.trackAwbPlaceholder ??
        `Enter Your ${strings[lang].searchByTrackingId}`;
    } else {
      searchPlaceholder =
        udfKeys?.trackOrderIdPlaceholder ??
        `Enter Your ${strings[lang].searchByOrderId}`;
    }

    return (
      <div
        className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 main-container
          ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
          ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
          ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
          ${
            user.subdomain == '1mg' || user.subdomain == '1mg-test'
              ? 'onemg'
              : user.subdomain
          }`}
      >
        <div
          className={`row col-gutter-200 ${
            user.subdomain == 'damensch' ? 'mg-top-10' : ''
          }`}
        >
          <div className="col-md-16 col-md-offset-4 col-xs-24">
            <div className="search">
              <div className="inputGroup left inline-radio">
                <label className={`label ${udfKeys.titleTextClassName}`}>
                  {strings[lang].searchBy}:
                </label>
              </div>
              {(tracking.theme.meta_data &&
                tracking.theme.meta_data.is_search_by_order_id) ||
              (tracking.theme.meta_data &&
                tracking.theme.meta_data.is_search_by_order_id ===
                  undefined) ? (
                <div className="inputGroup left inline-radio">
                  <input
                    id="order_id"
                    name="search-checkbox"
                    type="radio"
                    checked={this.state.searchBy === 'order_id'}
                    onChange={e => this.handleCheck(e)}
                  />
                  <label
                    className={`pre-text ${udfKeys.titleTextClassName}`}
                    htmlFor="order_id"
                  >
                    {strings[lang].searchByOrderId}
                  </label>
                </div>
              ) : null}
              <div className="inputGroup left inline-radio">
                <input
                  id="waybill"
                  name="search-checkbox"
                  type="radio"
                  checked={this.state.searchBy === 'waybill'}
                  onChange={e => this.handleCheck(e)}
                />
                <label
                  className={`pre-text ${udfKeys.titleTextClassName}`}
                  htmlFor="waybill"
                >
                  {udfKeys?.trackAwbRadioLabel ??
                    strings[lang].searchByTrackingId}
                </label>
              </div>
              <div className="clear-both" />
              <p className="input-group mrg-top-10">
                <div className="textbox container">
                  <input
                    type="text"
                    ref="searchInput"
                    className="textbox border-0"
                    placeholder={searchPlaceholder}
                    onChange={e => this.inputChange(e)}
                    onKeyPress={e => this.handleChange(e)}
                  />
                  {this.state.searchInput && (
                    <span
                      className="icon-close small"
                      onClick={this.clearInput}
                      tabIndex="0"
                      role="button"
                    />
                  )}
                </div>
                {/* <span
                  className="icon-search"
                  onClick={this.handleSearch}
                  tabIndex="0"
                  role="button"
                /> */}
                <span
                  className="track-order"
                  onClick={() => this.handleSearch(udfKeys)}
                  tabIndex="0"
                  role="button"
                >
                  {strings[lang].trackYourOrder}
                </span>
              </p>

              {this.state.invalidSearch ? (
                <div className="invalid-search">
                  <InvalidShipmentImage />
                  <p>{this.state.message}</p>
                  {user.subdomain === 'thenestery' && (
                    <p>
                      Please WhatsApp us at
                      <span>
                        <a
                          className="link-thenestery"
                          href="https://api.whatsapp.com/send/?phone=918951979362&text=Hi+Team+Nestery%2C+I+need+help+tracking+my+order&type=phone_number&app_absent=0"
                          target="blank"
                          rel="noopener noreferrer"
                        >
                          +91 8951979362
                        </a>
                        or email us at
                        <a
                          className="link-thenestery"
                          href="mailto:care@thenestery.in"
                          target="blank"
                          rel="noopener noreferrer"
                        >
                          care@thenestery.in
                        </a>
                        for assistance.
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                <>
                  <p className="mrg-top-10">
                    {strings[lang].checkCurrentStatus}
                  </p>
                  {user.subdomain === 'flomattress' && (
                    <p className="mrg-top-10">
                      If you don't have a tracking ID/AWB don't worry. You will
                      receive it as soon as your order is shipped
                    </p>
                  )}
                  {user.subdomain === 'buywow' && (
                    <p className="mrg-top-10">
                      <a
                        className="link-buywow"
                        target="blank"
                        rel="noopener noreferrer"
                        href="https://wa.link/mcwaff"
                      >
                        Chat with us to track your order using mobile number
                      </a>
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  user: PropTypes.object,
  page: PropTypes.object,
  tracking: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  trackingResetHandler: PropTypes.func,
  fetchTrackingHandler: PropTypes.func
};

Home.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  page: state.page
});

const mapDispatchToProps = dispatch => ({
  trackingResetHandler: () => dispatch(trackingResetHandler()),
  fetchTrackingHandler: query => dispatch(trackingHandler(query))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
